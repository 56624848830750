html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif !important;
}

input:focus {
  border: none;
  outline: none;
}

li {
  list-style: none;
}

a:hover {
  text-decoration: none;
}

.list-header {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 5;
}

.list-header button {
  height: 36px;
}

.list-header h4 {
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 0;
}

.list-header-list ul {
  padding: 0;
  margin: 0;
}

.list-header-list ul li {
  float: left;
  margin-right: 10px;
}

.list-header-list ul li a {
  color: #8a8a8a;
  line-height: 30px;
  font-size: 14px;
}

.list-header-list ul li a.active {
  color: #111;
  font-weight: bold;
  border-bottom: 2px solid #dd6161;
}

.toast-container {
  font-size: 14px;
}

.p-button:focus {
  box-shadow: none !important;
}

.disabled-copy {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.login {
  position: relative;
  background: #000;
  overflow: hidden;
}

.login .logo h4 {
  font-weight: 800;
  color: #fff;
  margin: 0 0 0 15px;
  cursor: default;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
}

.login:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.7;
  background-image: url("../../assets/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.login .login-content {
  position: relative;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  backdrop-filter: blur(5px);
  border-radius: 15px;
}

.login .input-group i {
  line-height: 40px !important;
  padding-left: 15px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  z-index: 999;
}

.login input[type="text"],
.login input[type="password"] {
  width: 100%;
  color: #fff;
  padding: 8px 10px 8px 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}

.login input:-internal-autofill-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
}

.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus,
.login textarea:-webkit-autofill,
.login textarea:-webkit-autofill:hover,
.login textarea:-webkit-autofill:focus,
.login select:-webkit-autofill,
.login select:-webkit-autofill:hover,
.login select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 14px;
}

.login input[type="text"].invalid,
.login input[type="password"].invalid {
  border-bottom-color: red;
}

.login input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.login input[type="submit"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  background-color: #dd6161;
  border: none;
  transition: all 0.1s ease;
}

.login input[type="submit"]:hover {
  background-color: #d93f3f;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.login .password {
  display: block;
  color: #fff;
  font-size: 12px;
}

.main {
  overflow: hidden;
}

.main .menu {
  width: 320px;
  height: 100%;
  background-color: #dd6161;
  float: left;
  padding: 35px 0 0 0;
  position: relative;
}

.menu .header {
  padding: 0 35px;
}

.menu .logo img {
  float: left;
}

.menu .logo .h4 {
  font-weight: 800;
  color: #fff;
  margin: 0 0 0 10px;
  cursor: default;
}

.menu .menu-items {
  margin: 50px 0 0 0;
  padding: 0 35px;
  overflow-y: auto;
  max-height: -moz-calc(100% - 240px);
  max-height: -webkit-calc(100% - 240px);
  max-height: -o-calc(100% - 240px);
  max-height: calc(100% - 240px);
}

.menu .menu-items li {
  margin-bottom: 20px;
}

.menu .menu-items li a {
  width: 100%;
  display: block;
  padding: 0;
  line-height: 36px;
  font-size: 15px;
  height: 36px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
}

.menu .menu-item-icon {
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  float: left;
  border-radius: 10px;
  margin-right: 8px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  transition: all 0.3s ease-out;
}

.menu .menu-items li a:hover,
.menu .menu-items li.active a,
.menu .menu-items li.active li.active a,
.menu .menu-items li li.active a {
  color: #fff;
}

.menu .menu-items li.active li a {
  color: rgba(255, 255, 255, 0.7);
}

.menu .menu-items li a:hover .menu-item-icon,
.menu .menu-items li.active a .menu-item-icon {
  background-color: #fff;
  color: #dd6161;
}

.menu .menu-items li.submenu a::after {
  display: none;
  right: 0;
  top: 0;
  height: 36px;
  width: 36px;
  float: right;
  display: inline-block;
  color: white;
  content: "";
  vertical-align: -0.125em;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: center center;
  transform: rotate(180deg);
}

.menu .menu-items li.submenu.active a::after {
  transform: rotate(0deg);
}

.menu .menu-items li.submenu li a::after {
  content: "";
  display: none;
}

.menu .menu-items li.submenu a::after {
  display: block;
}

.menu .menu-items li ul {
  float: left;
  display: none;
  width: 100%;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.menu .menu-items li.active ul {
  display: block;
}

.menu .menu-items li li {
  margin-bottom: 5px;
}

.menu .menu-items li li i {
  margin-right: 8px;
  float: left;
}

.menu .menu-items li li a {
  padding-left: 10px;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.menu .menu-items li li a:hover {
  color: #fff;
}

.menu .menu-items li li span {
  float: left;
}

.menu .footer {
  width: 100%;
  height: 80px;
  background-color: #9a4444;
  bottom: 0;
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
}

.menu .footer .avatar {
  height: 40px;
  width: 40px;
  overflow: hidden;
  float: left;
  border-radius: 50%;
  background-image: url("../../assets/images/avatar.jpg");
  background-size: cover;
}

.menu .footer .avatar img {
  width: 100%;
  height: 100%;
}

.menu .footer .quit {
  height: 40px;
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  float: right;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  text-align: center;
  cursor: pointer;
}

.menu .footer .quit:hover {
  background-color: #fff;
  color: #dd6161;
}

.menu .footer .data {
  font-size: 14px;
  float: left;
  width: -moz-calc(100% - 80px);
  width: -webkit-calc(100% - 80px);
  width: -o-calc(100% - 80px);
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.menu .footer .data .name {
  float: left;
  width: 100%;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
}

.menu .footer .data .role {
  float: left;
  width: 100%;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.main .content {
  float: left;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  width: -moz-calc(100% - 320px);
  width: -webkit-calc(100% - 320px);
  width: -o-calc(100% - 320px);
  width: calc(100% - 320px);
}

.main .content .box {
  background-color: #f8f8f8;
}

.gezi-custom-select .p-dropdown {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.gezi-custom-select .p-inputtext,
.gezi-custom-select .p-dropdown .p-dropdown-trigger,
.gezi-custom-select .p-component {
  color: #fff;
  font-size: 14px;
}

.gezi-custom-select .p-dropdown-panel .p-dropdown-items {
  padding: 0;
}

.gezi-custom-select .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.gezi-custom-select .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
  font-weight: bold;
}

.gezi-custom-select .p-dropdown-panel {
  border: none;
  border-radius: 5px;
  overflow: hidden;
}

.gezi-custom-select .p-dropdown,
.gezi-custom-select .p-dropdown:not(.p.disabled).p-focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 10px 10px !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 12px;
  font-weight: 600;
  color: #707070 !important;
  transition: box-shadow 0.15s;
}

.p-datatable {
  font-size: 12px;
  padding: 15px 35px;
  background: #f8f8f8;
  border-radius: 15px;
}

.p-datatable .p-datatable-tbody > tr {
  background-color: transparent !important;
  color: #121212 !important;
  border-bottom: 1px solid #eaeaea;
}

.p-datatable .p-datatable-tbody > tr:last-child {
  border-bottom: none !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: none !important;
  padding: 20px 10px !important;
  font-size: 14px;
}

.p-paginator {
  background: transparent !important;
  border: none !important;
  color: #8a8a8a !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent !important;
  border: none !important;
  color: #8a8a8a !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #fff !important;
  color: #000 !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent !important;
  border: none !important;
  color: #8a8a8a !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.page-list .author {
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 2px 5px;
  color: #2777d3;
  border-radius: 3px;
  max-height: 25px;
  /* overflow: hidden;
    display: table-caption;
    box-sizing: border-box; */
}

.page-list .confirm-active {
  background-color: #fff;
  border: 1px solid #34a677;
  padding: 2px 5px;
  color: #34a677;
  border-radius: 3px;
}

.page-list .confirm-waiting {
  background-color: #fff;
  border: 1px solid #eeb420;
  padding: 2px 5px;
  color: #eeb420;
  border-radius: 3px;
}

.page-list .confirm-deactive {
  background-color: #fff;
  border: 1px solid #8a8a8a;
  padding: 2px 5px;
  color: #8a8a8a;
  border-radius: 3px;
}

.page-list .confirm-delete {
  background-color: #fff;
  border: 1px solid #d93f3f;
  padding: 2px 5px;
  color: #d93f3f;
  border-radius: 3px;
}

.page-list .text-center {
  text-align: center;
}

.page-list .list.dropdown .dropdown-toggle::after {
  display: none !important;
}

.page-list .list.dropdown .dropdown-menu {
  --bs-position: end;
}

.p-dialog .p-dialog-footer .p-button {
  padding: 0.2rem 0.5rem;
}

.p-dialog .p-dialog-footer .p-confirm-dialog-accept {
  background: #28a745;
  border: 1px solid #28a745;
}

.p-dialog .p-dialog-footer .p-confirm-dialog-reject {
  background: #6c757d;
  border: 1px solid #6c757d;
}

.field-label {
  font-size: 12px;
  color: #8a8a8a;
  width: 100%;
}

.content p-dropdown {
  width: 100%;
}

.content .p-dropdown,
.content .p-calendar,
.content .p-inputtext,
.content .p-field-checkbox,
.content .p-multiselect,
.search-form .p-inputtext {
  width: 100%;
  background-color: #e8e8e8;
  font-size: 14px !important;
  color: #8a8a8a !important;
  border: transparent !important;
  border-radius: 3px;
}

.content p-dropdownitem:last-child li {
  border-bottom: none i !important;
}

.content .p-inputtext,
.content .p-dropdown .p-dropdown-trigger,
.content .p-component {
  font-size: 14px;
}

.content .p-dropdown-panel .p-dropdown-items,
.content .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

.content .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.content .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.5rem 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.content .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.content .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: rgba(0, 0, 0, 0.1);
  color: #dd6161;
  font-weight: bold;
}

.content .p-dropdown-panel,
.content .p-multiselect-panel {
  border: none;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
  box-shadow: 0px 0px 5px -3px #000000;
}

.content .p-dropdown,
.content .p-dropdown:not(.p.disabled).p-focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.content .p-dropdown:not(.p-disabled).p-focus,
.content .p-inputtext:enabled:focus,
.content .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.content .p-field-checkbox {
  padding: 8px 10px;
}

.content .p-field-checkbox label {
  display: flex;
  width: 100%;
  font-size: 13px !important;
  margin-bottom: 0px !important;
  line-height: 20px;
}

.content .p-tabview .p-tabview-panels {
  background-color: transparent !important;
  padding: 0 !important;
}

.content .p-tabview .p-tabview-nav {
  border: none !important;
}

.content .p-tabview .p-tabview-nav li {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.content .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: #e8e8e8 !important;
  color: #8a8a8a !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5rem 0.7rem;
}

.content .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #dd6161 !important;
  color: #fff !important;
}

.upload-photo .mat-form-field-flex {
  height: 40px !important;
  width: 80px !important;
  text-align: center;
  display: flex;
  align-items: center;
  color: #707070 !important;
  cursor: pointer;
  background-color: #c7c7c7;
  border-radius: 5px;
  padding: 0 !important;
  margin-right: 0px !important;
  transition: all 0.5s ease-out;
}

.upload-photo .story-input .mat-form-field-flex {
  height: 40px !important;
  width: 100% !important;
  text-align: left;
  display: flex;
  align-items: center;
  color: #333 !important;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #999;
  border-radius: 3px;
  padding: 0 !important;
  margin-right: 0px !important;
  transition: all 0.5s ease-out;
}

.upload-photo .story-input .mat-form-field-flex:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.upload-photo .uploadBtn {
  font-size: 13px;
  position: relative;
}

.upload-photo .p-fileupload .p-button {
  background: #555;
  border: none;
  padding: 0.5rem 1rem;
}

.upload-photo .deleteBtn {
  font-size: 13px;
  color: #fff;
  background-color: #dd6161;
  border-radius: 5px;
  margin-bottom: 0px;
  height: 40px !important;
  width: 80px !important;
  line-height: 25px;
}

.upload-photo .filename {
  display: none !important;
}

.upload-photo .mat-form-field-wrapper {
  padding: 0 !important;
  display: flex;
  justify-content: center;
}

.upload-photo .mat-form-field-infix {
  padding: 0 !important;
  border: none !important;
}

.upload-photo .mat-form-field-subscript-wrapper {
  display: none !important;
}

.upload-photo .mat-form-field-underline {
  display: none;
}

.content ngx-mat-file-input {
  display: block !important;
}

.pi {
  line-height: unset !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.p-dialog {
  border-radius: 15px !important;
  border: none !important;
  overflow: hidden;
}

.p-dialog-maximized {
  border-radius: 0 !important;
}

.p-confirm-popup:before,
.p-confirm-popup:after {
  display: none !important;
}

.mark-photos.upload .p-fileupload-basic {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mark-photos.upload .p-button.p-fileupload-choose {
  height: 75px;
  width: 75px;
}

.mark-photos.upload .p-button {
  border-radius: 50%;
  overflow: hidden;
  background-color: #e8e8e8 !important;
  color: #8a8a8a !important;
  border: none !important;
}

.mark-photos {
  display: block;
  height: 160px;
  border-radius: 5px;
  background-size: contain;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: relative;
  box-sizing: margin-box;
  width: calc(33% - 10px);
  background-repeat: no-repeat;
  background-color: rgb(160, 160, 160);
  background-position: center center;
}

.mark-photos:last-child {
  background-color: transparent;
}

.mark-photos .trash {
  position: absolute;
  margin-top: -8px;
  right: -8px;
  float: right;
  height: 30px !important;
  width: 30px !important;
}

.mark-photos .down {
  position: absolute;
  margin-top: 25px;
  right: -8px;
  float: right;
  height: 30px !important;
  width: 30px !important;
}

.info-box {
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 10px;
}

.info-box .icon {
  height: 40px;
  width: 40px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
}

.info-box p {
  margin: 0 !important;
}

.routelocations .marks {
  margin-bottom: 15px;
}

.routelocations .marks.shortList {
  /* background-color: rgba(32, 114, 238, 0.5); */
  /* padding: 5px 5px; */
  border-radius: 3px;
}
.routelocations .marks.list {
  /* background-color: rgba(238, 180, 32, 0.5); */
  /* padding: 5px 5px; */
  border-radius: 3px;
}
.routelocations .markContainer {
  /* background-color: rgba(97, 99, 98, 0.5); */
  /* padding: 5px; */
  border-radius: 3px;
}
.routelocations .shortList {
  background-color: rgb(32, 114, 238) !important;
  color: white !important;
}
.routelocations .list {
  background-color: rgb(238, 180, 32) !important;
  color: white !important;
}
.routelocations .marks:last-child {
  margin-bottom: 0;
}

.routelocations .mark-box {
  background-color: #e8e8e8;
  margin-right: 15px;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  color: #8a8a8a;
  font-size: 13px;
  overflow: hidden;
  /* border: 1px solid black; */
}

.routelocations .mark-box.delete {
  background-color: #dd6161;
  color: #fff;
}

.routelocations .mark-box:last-child {
  margin-right: 0;
}

.markaddress .p-dropdown .p-dropdown-label.p-placeholder {
  line-height: 25px !important;
}

/* .p-dropdown-clear-icon {
    display: none !important;
} */

.upload-photo .upload-img img {
  min-height: 100%;
}

.p-multiselect {
  min-width: 15rem;
  width: 100%;
}

/* .p-tooltip {
    z-index: 99999 !important;
} */

.user-page .avatar img {
  max-height: 150px;
  border-radius: 15px;
}

.user-dialog-datepicker {
  z-index: 999999 !important;
}

.user-promo {
  color: rgb(0, 68, 255);
  font-size: 12px !important;
}

.user-promo p {
  margin: 0 !important;
  padding: 0 !important;
}

.addPriceButton {
  background-color: #b3dd61;
  color: #fff;
  font-size: xx-large;
  font-weight: lighter;
}
.removePriceButton {
  background-color: #dd6161;
  color: #fff;
  font-size: xx-large;
  font-weight: lighter;
}
.colorPicker {
  cursor: pointer;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  background-color: #dd6161;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
